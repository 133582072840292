<template>
  <div>
    <!-- DESKTOP -->
    <div class="pl-8 pr-8 ml-3 mr-3 mb-xl-candidate-details">
      <v-row justify="center" class="mb-8">
        <v-col class="pl-0 pr-0 text-left" cols="12">
          <h1 class="candidate-details__title">Trayectoria de mi referido</h1>
        </v-col>
        <template v-if="showData == true">
          <v-timeline align-top :dense="$vuetify.breakpoint.smAndDown">
            <v-timeline-item
              v-for="(item, i) in items"
              v-if="item.show == true"
              :key="i"
              :color="item.color"
              :icon="item.icon"
              fill-dot
            >
              <v-card :color="item.color" dark>
                <v-card-title class="text-h6 title-card">
                  {{ item.status }}
                </v-card-title>
                <v-card-text
                  class="white text--primary mt-2"
                  v-if="item.message != ''"
                >
                  <p>{{ item.message }}</p>
                  <p :style="{ color: '#9C27B0' }" v-if="item.id == 10">
                    Proximamente tu bono estará disponible
                  </p>
                </v-card-text>
              </v-card>
            </v-timeline-item>
          </v-timeline>
        </template>
      </v-row>
    </div>
    <v-overlay :value="loadingAll">
      <v-progress-circular :size="120" color="#466BE3" indeterminate>
        {{ $translate("general.loading") }}
      </v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import Api from "@/utils/api";
export default {
  data() {
    return {
      items: [
        {
          status: "Creado",
          message: "¡Tu referido ha sido creado!",
          color: "#FFC0CB", // Rosa claro
          icon: "mdi-heart",
          id: 0,
          show: true,
        },
        {
          status: "Notificado",
          message:
            "¡Bienvenido tu referido! Ha iniciado su proceso de selección con nosotros.",
          color: "#1976D2", // Azul
          icon: "mdi-information",
          id: 4,
          show: false,
        },
        {
          status: "Notificación",
          message: "",
          color: "#1976D2", // Azul
          icon: "mdi-information",
          id: 5,
          show: false,
        },
        {
          status: "Entrevistas",
          message:
            "Conversando con tu referido. Estamos en la fase de conocer a tu referido.",
          color: "#00897B", // Verde Azulado
          icon: "mdi-chat",
          id: 3,
          show: false,
        },
        {
          status: "Aspirante datos generales",
          message:
            "Registro inicial. Tu referido está en la etapa de registro de datos y carga de documentos.",
          color: "#FF9800", // Naranja
          icon: "mdi-file-document-edit",
          id: 2,
          show: false,
        },
        {
          status: "Capacitación",
          message:
            "Adquiriendo conocimientos. Tu referido se está preparando para ser experto en su gestión.",
          color: "#4CAF50", // Verde
          icon: "mdi-school",
          id: 1,
          show: false,
        },
        {
          status: "Para contratación",
          message:
            "En proceso de contratación. Firmando documentos para un nuevo inicio laboral.",
          color: "#1976D2", // Azul
          icon: "mdi-file-document-outline",
          id: 7,
          show: false,
        },
        {
          status: "Otros Requisitos",
          message: "Faltan otros requisitos por cumplir.",
          color: "#E57373", // Rojo
          icon: "mdi-file-document-check",
          id: 6,
          show: false,
        },
        {
          status: "Previnculación",
          message: "Tu referido se encuentra en previnculación.",
          color: "#FF9800", // Naranja
          icon: "mdi-file-document-multiple-outline",
          id: 8,
          show: false,
        },
        {
          status: "Proceso cerrado-proceso exitoso",
          message:
            "¡Felicidades, has ganado! Oficialmente tu referido ha sido contratado, pronto obtendrás tu beneficio económico.",
          color: "#9C27B0", // Púrpura
          icon: "mdi-trophy",
          id: 10,
          show: false,
        },
        {
          status: "Procesos cerrados no exitosos",
          message:
            "¡Sigue intentándolo! Tu referido no continúa en el proceso pero tú tienes más oportunidades de referir y ganar.",
          color: "#F44336", // Rojo
          icon: "mdi-alert-circle",
          id: 9,
          show: false,
        },
      ],

      listDescriptions: [],
      listStatus: [
        "Pendiente entrevista",
        "Contratado",
        "No contratado",
        "Sin respuesta",
      ],
      form: {
        status: null,
        description: "",
      },
      search: "",
      reference_id: null,
      token: "",
      loadingAll: false,
      showData: false,
    };
  },
  methods: {
    getData() {
      var data = {
        reference_id: this.reference_id,
      };
      this.loadingAll = true;
      Api.reference()
        .listStatusReference(this.token, data)
        .then((res) => {
          if (res.data.cod == 0) {
            this.validateShow(res.data.data);
          }
        })
        .catch((error) => {
          this.loadingAll = false;
        })
        .finally(() => {
          this.showData = true;
          this.loadingAll = false;
        });
    },
    getToken() {
      var token = localStorage.getItem("token");
      var tokenb64 = localStorage.getItem("tokenB64");

      this.token = tokenb64;
      var div = token.split(".");
      if (div.length >= 2) {
        this.infoToken = JSON.parse(atob(div[1]));
        var nameList = this.infoToken.fullName.split(" ");

        if (nameList.length > 1) {
          if (nameList[0] != "") {
            this.name = nameList[0];
          } else {
            this.name = nameList[1];
          }
        } else {
          this.name = "";
        }
        // console.log(JSON.parse(atob(div[1])));
      } else {
        this.infoToken = {
          document: "",
        };
      }
    },
    validateShow(data) {
      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < this.items.length; j++) {
          if (data[i].status_id === this.items[j].id) {
            this.items[j].show = true;

            // Regla: si el id es 10, activar todos los anteriores excepto el 9
            if (this.items[j].id === 10) {
              for (let k = 0; k < j; k++) {
                if (this.items[k].id !== 9) {
                  this.items[k].show = true;
                }
              }
            }
            // Regla: si el id es 9, activar los elementos del 0 al 6 y desactivar el resto
            if (this.items[j].id === 9) {
              for (let k = 0; k < this.items.length; k++) {
                if (k <= 6) {
                  this.items[k].show = true;
                }
              }
            }
            // Regla: si el id es 8, activar todos los anteriores
            if (this.items[j].id === 8) {
              for (let k = 0; k < j; k++) {
                this.items[k].show = true;
              }
            }
          }
        }
      }
    },
  },
  mounted() {},
  created() {
    this.getToken();
    this.reference_id = this.$route.params.id;
    setTimeout(() => {
      this.getData();
    }, 1000);
  },
};
</script>
<style>
.title-card {
  width: 500px;
}

.candidate-details__data_title {
  font-family: "RobotoRegular";
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  display: flex;
  align-items: flex-end;
  color: #afaeae;
}

.candidate-details__data_txt {
  font-family: "RobotoLight";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;

  color: #353535;
}

.candidate-details__table {
  height: 420px;
  overflow-y: scroll;
}

.bg-rojo {
  background-color: red;
  /* Aquí establece el color de fondo deseado */
}
</style>
